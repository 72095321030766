import React from "react";
import { Helmet } from "react-helmet";
import { useThemeProvider } from "./ThemeProvider";
import { useTheme } from "styled-components";
import { useI18n } from "../hooks";

interface Meta {
  name: string;
  content: string;
}

export const HeaderMetaInitializer = (): JSX.Element => {
  const { theme } = useThemeProvider();
  const translate = useI18n();
  const themeConfig = useTheme();

  const metas: Partial<Record<Theme, Meta[]>> = {
    findlocaltrips: [
      {
        name: "description",
        content: translate(`header_meta.findlocaltrips.description`),
      },
    ],
  };

  const scripts: Partial<Record<Theme, string[]>> = {
    findlocaltrips: [
      `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-K494LZ8G');`,
    ],
  };

  return (
    <Helmet>
      <link rel="shortcut icon" href={`/favicons/favicon-${theme}.ico`} />
      <meta name="theme-color" content={themeConfig.colors.primary} />
      {metas[theme]?.map((meta, index) => (
        <meta key={index} name={meta.name} content={meta.content} />
      ))}
      {scripts[theme]?.map((script, index) => (
        <script key={index}>{script}</script>
      ))}
    </Helmet>
  );
};
