import React, { createContext, useContext } from "react";

interface Props {
  children: React.ReactElement;
}

interface Context {
  theme: Theme;
  subdomain: string;
  isDefaultTheme: boolean;
}

interface SubdomainAndTheme {
  theme: Theme;
  subdomain: Subdomain;
}

const DEFAULT_THEME: Theme = "findalocaltour";
const DEFAULT_SUBDOMAIN: Subdomain = "www";

export const ThemeProviderContext = createContext<Context>({
  theme: DEFAULT_THEME,
  subdomain: DEFAULT_SUBDOMAIN,
  isDefaultTheme: false,
});

export const useThemeProvider = (): Context => useContext(ThemeProviderContext);

export const ThemeProvider = ({ children }: Props): JSX.Element => {
  const hostName = window.location.hostname;

  const { theme, subdomain } = getSubdomainAndTheme(hostName);

  const isDefaultTheme = DEFAULT_THEME === theme;

  return (
    <ThemeProviderContext.Provider
      value={{
        theme: theme as Theme,
        isDefaultTheme,
        subdomain: subdomain,
      }}
    >
      {children}
    </ThemeProviderContext.Provider>
  );
};

const getQueryParam = (key: string): string | null => {
  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.get(key);
};

const getSubdomainAndTheme = (hostName: string): SubdomainAndTheme => {
  if (hostName === "localhost") {
    return {
      theme: (getQueryParam("theme") as Theme) || DEFAULT_THEME,
      subdomain: (getQueryParam("subdomain") as Subdomain) || DEFAULT_SUBDOMAIN,
    };
  }

  const [subdomain, theme] = hostName.split(".");
  return {
    theme: theme as Theme,
    subdomain: subdomain as Subdomain,
  };
};
