import React, { createContext, useContext, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { currentEnvironment, googleAnalytics } from "../config";
import ReactGA from "react-ga4";
import { useThemeProvider } from "./ThemeProvider";
import { limit, where } from "firebase/firestore";
import { useCollectionData, useDocumentData } from "../hooks/firebase";

export interface GAContext {
  gaClientId: string;
}

interface Props {
  children: React.ReactElement;
}

export const GoogleAnalyticsContext = createContext<GAContext>({
  gaClientId: "",
});

export const GoogleAnalyticsProvider = ({
  children,
}: Props): React.ReactElement => {
  const { isDefaultTheme } = useThemeProvider();

  const pathname = decodeURIComponent(window.location.pathname);
  // on page payment success (stripe checkout session)
  const operatorId = pathname.match(/\/operators\/([a-zA-Z0-9]+)\//)?.[1];

  const {
    data: [tour],
  } = useCollectionData<Tour>(
    "getDocs",
    "tours",
    [where("seoPaths", "array-contains", pathname), limit(1)],
    [isDefaultTheme],
    isDefaultTheme
  );

  const [operator] = useDocumentData<Operator>(
    "operators",
    isDefaultTheme ? undefined : operatorId || tour?.operatorId
  );

  const gaClientId = getGaClientId();

  const gaOptions = {
    clientId: gaClientId,
    ...(currentEnvironment === "development" && {
      debug_mode: true,
    }),
  };

  const trackings = [{ trackingId: googleAnalytics.measurementId, gaOptions }];

  useEffect(() => {
    if (isDefaultTheme) {
      ReactGA.initialize(trackings);
    } else {
      if (!operator) return;

      if (operator?.gaMeasurementId) {
        trackings.push({ trackingId: operator.gaMeasurementId, gaOptions });
      }

      ReactGA.initialize(trackings);
    }
  }, [operator?.id]);

  return (
    <GoogleAnalyticsContext.Provider value={{ gaClientId }}>
      {children}
    </GoogleAnalyticsContext.Provider>
  );
};

export const useGoogleAnalytics = (): GAContext =>
  useContext(GoogleAnalyticsContext);

const getGaClientId = (): string => {
  const storageKey = "gaClientId";

  const gaClientIdFromStorage = localStorage.getItem(storageKey);

  if (gaClientIdFromStorage) return gaClientIdFromStorage;

  const newGaClientId = uuidv4();

  localStorage.setItem(storageKey, newGaClientId);

  return newGaClientId;
};
