import { firestore } from "../";
import {
  fetchCollectionOnce,
  fetchDocumentOnce,
  setDocument,
  updateDocument,
} from "../firestore";
import {
  collection,
  doc,
  endAt,
  limit,
  orderBy,
  query,
  QueryConstraint,
  startAt,
  Timestamp,
  where,
} from "firebase/firestore";
import { toString } from "lodash";
import { _search } from "../../utils";
import { reservationsResultsLimit } from "../../config";
import moment from "moment";

export const reservationsRef = collection(firestore, "reservations");

export const getReservationId = (): string => doc(reservationsRef).id;

export const addReservation = async (reservation: Reservation): Promise<void> =>
  setDocument<Reservation>(doc(reservationsRef, reservation.id), reservation);

export const fetchReservation = (
  reservationId: string
): Promise<Reservation | undefined> =>
  fetchDocumentOnce<Reservation>(doc(reservationsRef, reservationId));

export const updateReservation = async (
  reservationId: string,
  reservation: Partial<Reservation>
): Promise<void> =>
  updateDocument<Partial<Reservation>>(
    doc(reservationsRef, reservationId),
    reservation
  );

export const fetchReservationByPurchaseNumberAndTourStartDate = async (
  purchaseNumber: string,
  startDate: string
): Promise<Reservation | undefined> => {
  const [firstReservation] = await fetchCollectionOnce<Reservation>(
    query(
      reservationsRef,
      where("purchaseNumber", "==", purchaseNumber),
      where("tourStartDate", "==", startDate)
    )
  );

  return firstReservation;
};

interface ReservationsQueryParams {
  operatorId?: string;
  typeDate: string;
  fromDate: string;
  toDate: string;
  pending: string;
  hosts: string;
  searchTerm?: string;
  agencyId?: string;
}

const DATE_FORMAT = "YYYY-MM-DD";

export const reservationsQuery = ({
  operatorId,
  fromDate,
  toDate,
  typeDate,
  searchTerm,
  pending,
  hosts,
  agencyId,
}: ReservationsQueryParams): QueryConstraint[] => {
  const query: QueryConstraint[] = [
    orderBy(typeDate === "tourDate" ? "tourStartDate" : "createAt", "desc"),
  ];

  if (operatorId) {
    query.push(where("tour.operatorId", "==", operatorId));
  }

  if (agencyId) {
    query.push(where("office.agencyId", "==", agencyId));
  }

  if (toString(pending) === "false") {
    query.push(where("status", "!=", "pending"));
  }

  if (toString(hosts) === "true") {
    query.push(where("user.roleCode", "==", "host"));
  }

  if (searchTerm) {
    query.push(
      where(
        "_search",
        "array-contains-any",
        _search(searchTerm.toString().split(" "))
      )
    );
  }

  if (typeDate === "tourDate") {
    query.push(
      where("tourStartDate", ">=", fromDate),
      where("tourStartDate", "<=", toDate)
    );
  }

  if (typeDate === "reservationDate") {
    const [startDate, endDate] = dateRange(fromDate, toDate);

    query.push(
      startAt(Timestamp.fromDate(endDate)),
      endAt(Timestamp.fromDate(startDate))
    );
  }

  query.push(limit(reservationsResultsLimit));

  return query;
};

const dateRange = (fromDate: string, toDate: string): [Date, Date] => {
  const startDate = moment(fromDate, DATE_FORMAT).startOf("day").toDate();
  const endDate = moment(toDate, DATE_FORMAT).endOf("day").toDate();

  return [startDate, endDate];
};

interface ReservationsQueryByMonthParams {
  typeDate: string;
  status: string;
  userId: string;
  month: string;
}

export const reservationsQueryByMonth = ({
  typeDate,
  status,
  userId,
  month,
}: ReservationsQueryByMonthParams): QueryConstraint[] => {
  const [startOfMonth, endOfMonth] = dateRangeByMonth(month);

  const query: QueryConstraint[] = [
    where("user.id", "==", userId),
    where("status", "==", status),
    orderBy(typeDate === "tourDate" ? "tourStartDate" : "bookAt", "desc"),
  ];

  if (typeDate === "tourDate") {
    query.push(
      where("tourStartDate", ">=", moment(startOfMonth).format(DATE_FORMAT)),
      where("tourStartDate", "<=", moment(endOfMonth).format(DATE_FORMAT))
    );
  }

  if (typeDate === "reservationDate") {
    query.push(
      startAt(Timestamp.fromDate(endOfMonth)),
      endAt(Timestamp.fromDate(startOfMonth))
    );
  }

  query.push(limit(500));

  return query;
};

const dateRangeByMonth = (month: string): [Date, Date] => {
  const startDate = moment(month, "YYYY-MM")
    .startOf("month")
    .startOf("day")
    .toDate();
  const endDate = moment(month, "YYYY-MM").endOf("month").endOf("day").toDate();

  return [startDate, endDate];
};
