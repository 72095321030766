/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';

/**
 * 
 * @export
 * @interface LanguageSearch
 */
export interface LanguageSearch {
    /**
     * 
     * @type {string}
     * @memberof LanguageSearch
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LanguageSearch
     */
    operatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof LanguageSearch
     */
    countryName?: string;
    /**
     * 
     * @type {string}
     * @memberof LanguageSearch
     */
    cityName?: string;
    /**
     * 
     * @type {Currency}
     * @memberof LanguageSearch
     */
    currencyCode?: Currency;
    /**
     * 
     * @type {number}
     * @memberof LanguageSearch
     */
    rating?: number;
    /**
     * 
     * @type {string}
     * @memberof LanguageSearch
     */
    title: string | null;
    /**
     * 
     * @type {string}
     * @memberof LanguageSearch
     */
    tags?: string;
    /**
     * 
     * @type {string}
     * @memberof LanguageSearch
     */
    photoName: string | null;
    /**
     * 
     * @type {string}
     * @memberof LanguageSearch
     */
    minPrice?: string;
    /**
     * 
     * @type {string}
     * @memberof LanguageSearch
     */
    seoPath: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LanguageSearch
     */
    hidePublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LanguageSearch
     */
    isActivity?: boolean;
}

/**
 * Check if a given object implements the LanguageSearch interface.
 */
export function instanceOfLanguageSearch(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "photoName" in value;
    isInstance = isInstance && "seoPath" in value;

    return isInstance;
}

export function LanguageSearchFromJSON(json: any): LanguageSearch {
    return LanguageSearchFromJSONTyped(json, false);
}

export function LanguageSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): LanguageSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'operatorId': !exists(json, 'operatorId') ? undefined : json['operatorId'],
        'countryName': !exists(json, 'countryName') ? undefined : json['countryName'],
        'cityName': !exists(json, 'cityName') ? undefined : json['cityName'],
        'currencyCode': !exists(json, 'currencyCode') ? undefined : CurrencyFromJSON(json['currencyCode']),
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
        'title': json['title'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'photoName': json['photoName'],
        'minPrice': !exists(json, 'minPrice') ? undefined : json['minPrice'],
        'seoPath': json['seoPath'],
        'hidePublic': !exists(json, 'hidePublic') ? undefined : json['hidePublic'],
        'isActivity': !exists(json, 'isActivity') ? undefined : json['isActivity'],
    };
}

export function LanguageSearchToJSON(value?: LanguageSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'operatorId': value.operatorId,
        'countryName': value.countryName,
        'cityName': value.cityName,
        'currencyCode': CurrencyToJSON(value.currencyCode),
        'rating': value.rating,
        'title': value.title,
        'tags': value.tags,
        'photoName': value.photoName,
        'minPrice': value.minPrice,
        'seoPath': value.seoPath,
        'hidePublic': value.hidePublic,
        'isActivity': value.isActivity,
    };
}

